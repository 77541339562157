import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, TypographyStyledGlobal, fontWeight } from '../../../theme';
import Loader from '../../Loader/loader';
import ChatbotModal from '../../Modals/DashboardModal/chatbotModal';
import TextTypingEffectWithTextsFadeOut from '../../Modals/DashboardModal/DashboardModalContent/ChatbotModal/typingEffect';
import { ChatingProps } from '../../Modals/DashboardModal/dashboardModalInterface';
import { ClauseFilterLabelProps } from '../dashboardInterfaces';
import { ButtonDiv, ConversationButtonStyled, TextVerbatimStyled, ChatbotContainerStyled } from '../styled';
import { ReactComponent as ChatbotConversationImage } from '../../../assets/charts/chatbot-conversation.svg';
import { ReactComponent as ChatbotSummaryImage } from '../../../assets/charts/chatbot-summary.svg';

type ChatbotIndicatorProps = {
    question: any,
    selectedClauseFilterLabel?: Array<ClauseFilterLabelProps>,
    getChatbotResponse: (isSummary: boolean, question: string) => void,
    conversation: Array<ChatingProps>,
    summary: string,
    isSummaryDisplayed: boolean,
    waitingForChatAnswer: boolean,
    handleIsRenderedChat: (chatIdRendered: number) => void,
}

const ChatbotIndicator: FC<ChatbotIndicatorProps> = ({ question, selectedClauseFilterLabel, getChatbotResponse, conversation, summary, isSummaryDisplayed, waitingForChatAnswer, handleIsRenderedChat }) => {
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation('dashboard');

    return (
        <>
            <ChatbotContainerStyled scroll='scroll'>
                <TypographyStyledGlobal fontSize='16px' margin='0px 0px 0px 15px' padding='10px 0px 0px 0px' textAlign='left' weight='500'>
                    {isSummaryDisplayed ? t('addIndicatorModal.addChatbotIndicator.title') : t('addIndicatorModal.addChatbotIndicator.chooseAnAction')}
                </TypographyStyledGlobal>
            </ChatbotContainerStyled>
            { isSummaryDisplayed && (
                <ChatbotContainerStyled scroll={summary ? 'scroll' : 'none'}>
                    { summary && (
                        <TextVerbatimStyled>
                            <TextTypingEffectWithTextsFadeOut handleIsRenderedChat={handleIsRenderedChat} text={summary} hasToUseTypingEffect />
                        </TextVerbatimStyled>
                    )}
                    { !summary && (
                        <Loader size={30} />
                    )}
                </ChatbotContainerStyled>
            )}
            <ButtonDiv>
                <ConversationButtonStyled bgColor={colors.default} bgColorHover={colors.defaultHover} fontColor='white' margin='0px 0px 0px 15px' onClick={() => { getChatbotResponse(true, ''); }} boxShadow>
                    <ChatbotSummaryImage height={25} width={25} />
                    <TypographyStyledGlobal
                        margin='0px'
                        weight={fontWeight.regular}
                    >
                        {t('addIndicatorModal.addChatbotIndicator.summary')}
                    </TypographyStyledGlobal>
                </ConversationButtonStyled>
                <ConversationButtonStyled bgColor={colors.default} bgColorHover={colors.defaultHover} fontColor='white' margin='0px 0px 0px 15px' onClick={() => setOpenModal(true)} boxShadow>
                    <ChatbotConversationImage height={25} width={25} />
                    <TypographyStyledGlobal
                        margin='0px'
                        weight={fontWeight.regular}
                    >
                        {t('addIndicatorModal.addChatbotIndicator.conversation')}
                    </TypographyStyledGlobal>
                </ConversationButtonStyled>
            </ButtonDiv>
            <ChatbotModal conversation={conversation} getChatbotResponse={getChatbotResponse} handleIsRenderedChat={handleIsRenderedChat} openModal={openModal} questionLabel={question.label} selectedClauseFilterLabel={selectedClauseFilterLabel} setOpenModal={setOpenModal} waitingForChatAnswer={waitingForChatAnswer} />
        </>
    );
};

export default ChatbotIndicator;
