import Fade from '@mui/material/Fade';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import updateIndicatorFetcher from '../../../api/indicator/updateIndicatorFetcher';
import { DashboardParams, Indicator } from '../../Dashboard/dashboardInterfaces';
import Title from '../../Title/title';
import BoxModalStyled from '../styled';
import ActionsButton from './ActionButton';
import CustomTitleInput from './customTitleInput';
import { EditIndicatorProps } from './EditModalInterface';
import { CloseIconModalStyled, ModalStyled } from './styled';

const EditIndicatorModal: FC<EditIndicatorProps> = ({ openModal, setOpenModal, indicator, questionLabel }) => {
    const { t } = useTranslation('dashboard');
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const { mutate } = useSWRConfig();
    const dashboardUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}`;

    const [newIndicator, setNewIndicator] = useState<Indicator>(indicator);
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickConfirm = () => {
        updateIndicatorFetcher(
            `${dashboardUrl}/indicator/${indicator.id}`,
            newIndicator,
            t('editIndicatorModal.success'),
            t('editIndicatorModal.error'),
        );
        mutate(`${dashboardUrl}/indicator`);
        setOpenModal(false);
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <Title content={t('editIndicatorModal.title', { cardTitle: questionLabel })} />
                    <CustomTitleInput newIndicator={newIndicator} setNewIndicator={setNewIndicator} />
                    <CloseIconModalStyled onClick={handleClose} />
                    <ActionsButton
                        handleClickConfirm={handleClickConfirm}
                        labelConfirm={t('editIndicatorModal.confirmButton')}
                    />
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default EditIndicatorModal;
