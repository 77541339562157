import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FC } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { colors } from '../../../theme';
import { CALCULATION_MODE } from '../../Modals/DashboardModal/constants';
import { CrosstableProps } from '../dashboardInterfaces';
import { TableCellBodyStyled, TableCellHeadStyled, TableCellBaseStyled } from '../styled';

interface CrosstableIndicatorProps {
    crosstableResult: CrosstableProps
    isZoomed: boolean
}

interface ColumnData {
    dataKey: number;
    label: string;
    numeric?: boolean;
    width?: number;
}

interface RowData {
    res?: number;
    type?: string;
}

const scrollbarStyles = {
    '&::-webkit-scrollbar': {
        height: '8px', // Customize the scrollbar height here
        width: '12px',
    },
    '&::-webkit-scrollbar-track': {
        background: colors.grey600,
        borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: colors.grey400,
        borderRadius: '6px',
        '&:hover': {
            background: '#555',
        },
    },
};

const VirtuosoTableComponents: TableComponents<any> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} sx={{ ...scrollbarStyles }} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableHead {...props} ref={ref} />
    )),
    TableRow,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

const formateValue = ({ res, type }: RowData) => {
    if (!res) {
        return '-';
    }

    switch (type) {
        case CALCULATION_MODE.PERCENTAGE: return `${res}%`;
        case CALCULATION_MODE.AVERAGE: return res;
        case CALCULATION_MODE.NPS: return `${res >= 0 ? '+' : ''}${res}`;
        default: return res;
    }
};
const getLabelColumnWidth = (labels: string[]) => {
    const maxLength = Math.max(...labels.map((label) => label.length));
    const columnWidth = maxLength * 7;

    return (columnWidth > 100) ? 100 : columnWidth;
};

const CrosstableIndicator: FC<CrosstableIndicatorProps> = ({ crosstableResult, isZoomed }) => {
    if (crosstableResult.base === 0) {
        return null;
    }
    const labelColumnWidth = getLabelColumnWidth(crosstableResult.rows.map((row) => row.label));
    const totalColumnPosition = labelColumnWidth + 32;
    const columns: ColumnData[] = crosstableResult.columns.map((column, index) => ({ width: index === 0 ? 50 : 100, label: column.label, dataKey: column.id, computeMethod: crosstableResult.computeMethod }));
    const dataRows: RowData[][] = crosstableResult.rows.map((row) => (
        crosstableResult.datasets.map((dataset) => {
            // Find matching data for the current row and dataset
            const matchingData = dataset.datas.find((data) => data.modal === row.id);

            return {
                res: matchingData?.data.current?.res,
                type: matchingData?.type,
            };
        })
    ));

    const fixedHeaderContent = () => (
        <>
            <TableRow>
                <TableCell
                    style={{ width: labelColumnWidth }}
                    sx={{ backgroundColor: 'background.paper', border: 'none', position: 'sticky', left: 0, zIndex: 10 }}
                    variant="head"
                />
                {columns.map((column, index) => (
                    <TableCellHeadStyled
                        key={column.dataKey}
                        align='center'
                        cellWidth={column.width}
                        isTotal={index === 0}
                        sx={index === 0 ? { backgroundColor: 'background.paper', border: 'none', position: 'sticky', left: totalColumnPosition, zIndex: 10 } : { backgroundColor: 'background.paper', border: 'none', position: 'sticky', left: 0 }}
                        variant='head'
                    >
                        {column.label}
                    </TableCellHeadStyled>
                ))}
            </TableRow>
            <TableRow>
                <TableCellBaseStyled
                    align='right'
                    style={{ width: labelColumnWidth, padding: 8 }}
                    sx={{ backgroundColor: 'background.paper', position: 'sticky', left: 0, zIndex: 10, color: colors.grey100, fontStyle: 'italic' }}
                    variant="head"
                    isFirstColumn
                >
                    Bases :
                </TableCellBaseStyled>
                {columns.map((column, index) => (
                    <TableCellBaseStyled
                        key={column.dataKey}
                        align='center'
                        cellWidth={column.width}
                        isTotal={index === 0}
                        sx={index === 0 ? { backgroundColor: 'background.paper', position: 'sticky', left: totalColumnPosition, zIndex: 10 } : { backgroundColor: 'background.paper', border: 'none', position: 'sticky', left: 0 }}
                        variant='head'
                    >
                        {crosstableResult.bases[index]}
                    </TableCellBaseStyled>
                ))}
            </TableRow>
        </>
    );

    const rowContent = (rowIndex: number, row: any) => (
        <>
            <TableCellHeadStyled align='right' cellWidth={labelColumnWidth} sx={{ background: 'white', position: 'sticky', left: 0, border: 'none' }}>
                { crosstableResult.rows[rowIndex].label }
            </TableCellHeadStyled>
            {columns.map((column, index) => (
                <TableCellBodyStyled
                    key={column.dataKey}
                    align='center'
                    cellWidth={column.width}
                    isEven={rowIndex % 2 === 0}
                    isFirstColumn={index === 0}
                    isSpecific={crosstableResult.rows[rowIndex].id <= -9998}
                    isTotal={index === 0}
                    sx={index === 0 ? { position: 'sticky', left: totalColumnPosition } : {}}
                    variant="head"
                >
                    {formateValue(row[index])}
                </TableCellBodyStyled>
            ))}
        </>
    );

    return (
        <TableVirtuoso
            components={VirtuosoTableComponents}
            data={dataRows}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
            style={{ height: 130 + (42 * crosstableResult.rows.length), maxHeight: (isZoomed ? 10000 : 450), willChange: 'transform', boxShadow: 'none' }}
        />
    );
};

export default CrosstableIndicator;
