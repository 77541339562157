import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AuthorizedContent } from '@frontegg/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RolesType from '../../utils/enum/RolesType';
import { ChatingProps } from '../Modals/DashboardModal/dashboardModalInterface';
import AddIndicatorButton from './AddIndicatorButton/addIndicatorButton';
import {
    Indicator,
    ClauseFilterProps,
    DateRangeScopeProps,
    ScopeProps,
} from './dashboardInterfaces';
import SortableIndicatorCard from './IndicatorCard/sortableIndicatorCard';
import { ColumnDashboardCharts } from './styled';

interface ColumnDroppableProps {
    columnNumber: string
    indicatorsByColumns: {[key: number]: Array<Indicator>}
    isDragging: string | null
    selectIndicatorCard: (additionalVars: Indicator['additionalVars'], displayType: Indicator['displayType'], data: Indicator['data'], customTitle: Indicator['customItemTitle'], question: Indicator['question'], getChatbotResponse: (isSummary: boolean, question: string) => void, conversation: Array<ChatingProps>, summary: string, isSummaryDisplayed: boolean, waitingForChatAnswer: boolean, handleIsRenderedChat: (chatId: number) => void) => any,
    selectedClauseFilter: Array<ClauseFilterProps>
    selectedScope: Array<ScopeProps>
    selectedDateRange: DateRangeScopeProps | null
}

const ColumnDroppable: React.FC<ColumnDroppableProps> = (props) => {
    const { columnNumber, indicatorsByColumns, isDragging, selectIndicatorCard, selectedClauseFilter, selectedScope, selectedDateRange } = props;
    const { t } = useTranslation('dashboard');
    const { setNodeRef } = useDroppable({ id: columnNumber });
    const columnsLength = Object.keys(indicatorsByColumns).map((columnId: string) => ({
        columnId: parseInt(columnId, 10),
        columnLength: indicatorsByColumns[parseInt(columnId, 10)].length,
    }));
    const lastColumn = columnsLength.reduce((min, current) => (current.columnLength < min.columnLength ? current : min)).columnId;
    const lastRank = (indicatorsByColumns[lastColumn].length > 0) ? (indicatorsByColumns[lastColumn].reduce((max, current) => (current.rank > max.rank ? current : max)).rank + 1) : indicatorsByColumns[lastColumn].length;
    const rankingParameter = {
        columnToApply: lastColumn,
        rankToApply: lastRank,
    };

    return (
        <ColumnDashboardCharts key={columnNumber} ref={setNodeRef} id={`column-${columnNumber}`}>
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                {columnNumber === '0' && (
                    <AddIndicatorButton label={t('addIndicator')} rankingParameter={rankingParameter} />
                )}
            </AuthorizedContent>
            <SortableContext
                id={`sort-column-${columnNumber}`}
                items={indicatorsByColumns[parseInt(columnNumber, 10)].map((indicator) => (indicator.id))}
                strategy={verticalListSortingStrategy}
            >
                {indicatorsByColumns[parseInt(columnNumber, 10)].map((indicator: Indicator) => (
                    <SortableIndicatorCard
                        key={indicator.id}
                        indicator={indicator}
                        isDragging={String(indicator.id) === isDragging}
                        selectIndicatorCard={selectIndicatorCard}
                        selectedClauseFilter={selectedClauseFilter}
                        selectedDateRange={selectedDateRange}
                        selectedScope={selectedScope}
                        isDraggable
                    />
                ))}
            </SortableContext>
        </ColumnDashboardCharts>
    );
};

export default ColumnDroppable;
