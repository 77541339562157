import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChatbotChart } from '../../../assets/charts/chatbot.svg';
import { ReactComponent as CosstableChart } from '../../../assets/charts/crosstable.svg';
import { ReactComponent as DonutChart } from '../../../assets/charts/donut.svg';
import { ReactComponent as GraphChart } from '../../../assets/charts/graph-horizontal.svg';
import { ReactComponent as KeyNumberChart } from '../../../assets/charts/key-number.svg';
import { ReactComponent as LineChart } from '../../../assets/charts/line-chart.svg';
import { ReactComponent as VerbatimChart } from '../../../assets/charts/verbatim.svg';

export enum QUESTION_TYPE {
    NUMERIC = 'Numeric',
    OPEN = 'Open',
    CLOSED_DATA = 'ClosedData',
    CLOSED_DATA_MULTIPLE = 'ClosedData',
}

export enum DISPLAY_TYPE {
    KPI = 'KPI',
    IMAGE = 'IMAGE'
}

export enum KPI_STATE {
    DISPLAYED = 'DISPLAYED',
    HIDDEN = 'HIDDEN',
    EXCLUDED = 'EXCLUDED',
    UNKNOWN= 'UNKNOWN'
}

export enum CHART_INDICATOR_NAME {
    KEY_FIGURE = 'KEY_FIGURE',
    DONUT = 'DONUTS',
    VERBATIM = 'VERBATIM',
    HORIZONTAL_BAR = 'HORIZONTAL',
    LINE_CHART = 'LINE_CHART',
    VERBATIM_PN = 'VERBATIM_PN',
    CHATBOT = 'CHATBOT',
    CROSS_TABLE = 'CROSS_TABLE',
}

export enum CALCULATION_MODE {
    PERCENTAGE = 'PERCENT',
    AVERAGE = 'AVERAGE',
    NPS = 'NPS',
}

export enum LAST_PAGE_FOR_EDIT {
    KEY_FIGURE = 'addKeyFigureIndicator',
    DONUT = 'addDonutIndicator',
    VERBATIM = 'addGlobalVerbatimIndicator',
    HORIZONTAL_BAR = 'addHorizontalBarIndicator',
    LINE_CHART = 'addLineChartIndicator',
    VERBATIM_PN = 'addNegativeVerbatimIndicator',
    CROSS_TABLE = 'addCrossTableIndicator',
    CHATBOT = 'addChatbotIndicator',
}
export const getLastPageForEditKpi = (chartIndicatorTypeEnum : CHART_INDICATOR_NAME) => {
    const lastPages = [
        {
            id: CHART_INDICATOR_NAME.KEY_FIGURE,
            lastPage: 'addKeyFigureIndicator',
        },
        {
            id: CHART_INDICATOR_NAME.DONUT,
            lastPage: 'addDonutIndicator',
        },
        {
            id: CHART_INDICATOR_NAME.VERBATIM,
            lastPage: 'addGlobalVerbatimIndicator',
        },
        {
            id: CHART_INDICATOR_NAME.HORIZONTAL_BAR,
            lastPage: 'addHorizontalBarIndicator',
        },
        {
            id: CHART_INDICATOR_NAME.LINE_CHART,
            lastPage: 'addLineChartIndicator',
        },
        {
            id: CHART_INDICATOR_NAME.VERBATIM_PN,
            lastPage: 'addNegativeVerbatimIndicator',
        },
        {
            id: CHART_INDICATOR_NAME.CHATBOT,
            lastPage: 'selectDisplayType',
        },
        {
            id: CHART_INDICATOR_NAME.CROSS_TABLE,
            lastPage: 'addCrosstableIndicator',
        },
    ];

    return lastPages.find((el) => el.id === chartIndicatorTypeEnum)?.lastPage;
};

export const useChartElement = () => {
    const { t } = useTranslation('dashboard');

    return [
        {
            key: CHART_INDICATOR_NAME.KEY_FIGURE,
            image: <KeyNumberChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.keyNumber'),
            nextStep: 'addKeyFigureIndicator',
        },
        {
            key: CHART_INDICATOR_NAME.HORIZONTAL_BAR,
            image: <GraphChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.horizontalBar'),
            nextStep: 'addHorizontalBarIndicator',
        },
        {
            key: CHART_INDICATOR_NAME.DONUT,
            image: <DonutChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.donut'),
            nextStep: 'addDonutIndicator',
        },
        {
            key: CHART_INDICATOR_NAME.VERBATIM,
            image: <VerbatimChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.verbatim'),
            nextStep: 'addGlobalVerbatimIndicator',
        },
        {
            key: CHART_INDICATOR_NAME.LINE_CHART,
            image: <LineChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.lineChart'),
            nextStep: 'addLineChartIndicator',
        },
        {
            key: CHART_INDICATOR_NAME.CHATBOT,
            image: <ChatbotChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.chatbot'),
            nextStep: 'addChatbotIndicator',
        },
        {
            key: CHART_INDICATOR_NAME.CROSS_TABLE,
            image: <CosstableChart />,
            title: t('addIndicatorModal.selectDisplayType.indicatorChart.crosstable'),
            nextStep: 'selectCrossingIndicatorQuestion',
        },
    ];
};

export const useCalculationMode = () => {
    const { t } = useTranslation('dashboard');

    return [
        {
            id: 1,
            mode: CALCULATION_MODE.PERCENTAGE,
            label: t('addIndicatorModal.commonAddIndicator.percentage'),
            questionType: [QUESTION_TYPE.CLOSED_DATA],
        },
        {
            id: 2,
            mode: CALCULATION_MODE.AVERAGE,
            label: t('addIndicatorModal.commonAddIndicator.mean'),
            questionType: [QUESTION_TYPE.NUMERIC, QUESTION_TYPE.CLOSED_DATA],
        },
        {
            id: 3,
            mode: CALCULATION_MODE.NPS,
            label: t('addIndicatorModal.commonAddIndicator.NPS'),
            questionType: [QUESTION_TYPE.CLOSED_DATA],
        },
    ];
};

export const fakeQuestions = [
    {
        id: 1,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.NUMERIC,
        estMultiple: false,
        modalities: [],
    },
    {
        id: 2,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.OPEN,
        estMultiple: false,
        modalities: [],
    },
    {
        id: 3,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ou non ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: true,
        modalities: [
            {
                id: 0,
                modalityLabel: 'Je ne pense pas',
            },
            {
                id: 1,
                modalityLabel: 'Oui',
            },
            {
                id: 2,
                modalityLabel: 'Cela dépend de la météo',
            },
        ],
    },
    {
        id: 4,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: false,
        modalities: [
            {
                id: 2,
                modalityLabel: 'string',
            },
        ],
    },
    {
        id: 5,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: false,
        modalities: [
            {
                id: 3,
                modalityLabel: 'string',
            },
        ],
    },
    {
        id: 6,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: false,
        modalities: [
            {
                id: 2,
                modalityLabel: 'string',
            },
        ],
    },
    {
        id: 7,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: false,
        modalities: [
            {
                id: 2,
                modalityLabel: 'string',
            },
        ],
    },
    {
        id: 8,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: false,
        modalities: [
            {
                id: 2,
                modalityLabel: 'string',
            },
            {
                id: 5,
                modalityLabel: 'string4',
            },
        ],
    },
    {
        id: 9,
        label: 'Recommanderiez-vous DPD France à l’une de vos relations professionnelles ?',
        type: QUESTION_TYPE.CLOSED_DATA,
        estMultiple: false,
        modalities: [
            {
                id: 5,
                modalityLabel: 'Oui',
            },
            {
                id: 6,
                modalityLabel: 'Non',
            },
        ],
    },
];

export const fakeAnswers: { id: number, content: string}[] = [
    {
        id: 11,
        content: "Affiliation en ligne : quand on est à la fois employeur et salarié, ce n'est pas simple de s'y retrouver - Rendre le site plus ergonomique",
    },
    {
        id: 12,
        content: 'Plus de savoir du conseiller pour mieux orienter, être plus compétent pour mieux conseiller.',
    },
    {
        id: 13,
        content: "Mieux gérer les cas par cas, selon les situations (par exemple, je considère que c'était aux grands groupes comme AG2R de s'adapter aux plus petites entreprises pendant la crise COVID).",
    },
    {
        id: 14,
        content: "Un meilleur accompagnement dans le processus d'inscription.",
    },
];

export const adaptTranslationByChartTypeTranslationByChartType = (displayType: CHART_INDICATOR_NAME) => {
    const { t } = useTranslation('dashboard');

    switch (displayType) {
        case CHART_INDICATOR_NAME.DONUT:
            return {
                title: t('addIndicatorModal.addDonutIndicator.changeTitle'),
                button: t('addIndicatorModal.addDonutIndicator.addIndicator'),
            };
        case CHART_INDICATOR_NAME.HORIZONTAL_BAR:
            return {
                title: t('addIndicatorModal.addHorizontalBarIndicator.changeTitle'),
                button: t('addIndicatorModal.addHorizontalBarIndicator.addIndicator'),
            };
        case CHART_INDICATOR_NAME.KEY_FIGURE:
            return {
                title: t('addIndicatorModal.addKeyFigureIndicator.changeTitle'),
                button: t('addIndicatorModal.addKeyFigureIndicator.addIndicator'),
            };
        default:
            return {
                title: t('addIndicatorModal.commonAddIndicator.changeTitle'),
                button: t('addIndicatorModal.commonAddIndicator.addIndicator'),
            };
    }
};

export const CreateIndicatorContext = createContext({
    sendCreateIndicator: false,
    setSendCreateIndicator: (auth: any) => { console.info(auth); },
});
