import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { colors, gutterSize } from '../../../theme';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    ChartDataLabels,
);

enum dataLabelAlignAnchorType {
    END = 'end',
}

enum dataLabelWeight {
    BOLD = 'bold'
}

type HorizontalBarIndicatorProps = {
    listOfValues: any
}

const HorizontalBarIndicator: FC<HorizontalBarIndicatorProps> = ({ listOfValues }) => {
    const sortedData = Object.entries(listOfValues.data)
        .sort((a, b) => {
            const rankA = listOfValues.ranking[a[0]];
            const rankB = listOfValues.ranking[b[0]];

            return rankA - rankB;
        });

    const labels = sortedData.map(([key]) => key);
    const options = {
        indexAxis: 'y' as const,
        responsive: true,
        layout: {
            padding: {
                left: gutterSize.l,
                right: gutterSize.xxl,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: true,
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    autoSkip: false,
                    font: (context: any) => {
                        const { width } = context.chart;
                        const size = Math.round(width / 38);

                        return {
                            color: colors.grey200,
                            size,
                            family: 'Barlow',
                        };
                    },
                    callback: (value: any) => {
                        let label = labels[value];
                        if (label == null) {
                            return '';
                        }
                        if (label.length > 20) {
                            label = label.substring(0, 20);
                            label += '..';
                        }

                        return label;
                    },
                },
            },
        },
    };

    const values = sortedData.map(([, value]) => value);

    const data = {
        labels,
        datasets: [{
            maxBarThickness: 14,
            data: values,
            backgroundColor: [
                colors.blue000,
            ],
            borderRadius: 50,
            borderSkipped: false,
            datalabels: {
                align: dataLabelAlignAnchorType.END,
                display: true,
                anchor: dataLabelAlignAnchorType.END,
                formatter: (value: number) => `${Number(value).toFixed(listOfValues.round)}%`,
                font: (context: any) => {
                    const { width } = context.chart;
                    const size = Math.round(width / 38);

                    return {
                        size,
                        weight: dataLabelWeight.BOLD,
                    };
                },
            },
        }],
    };

    return (
        <Bar
            data={data}
            options={options}
            style={{
                breakInside: 'avoid',
                breakAfter: 'avoid',
            }}
        />
    );
};

export default HorizontalBarIndicator;
