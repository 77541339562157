import React, { FC } from 'react';
import useTypingEffect from './useTypingEffect';

interface TextTypingEffectWithTextsFadeOutProps {
    chatId?: number,
    text: string,
    hasToUseTypingEffect: boolean,
    handleIsRenderedChat: (chatIdRendered: number) => void,
}

const TIME_PER_LETTER = 25;
const formatText = (text: string): string => {
    // Replace all occurrences of **text** with <b>text</b>
    let returnText = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    returnText = returnText.replace(/(\r\n|\n|\r)/gm, '<br>');

    return returnText;
};
const TextTypingEffectWithTextsFadeOut: FC<TextTypingEffectWithTextsFadeOutProps> = ({ chatId, handleIsRenderedChat, text, hasToUseTypingEffect = true }) => {
    const textToShow = useTypingEffect(text, TIME_PER_LETTER, false, hasToUseTypingEffect, handleIsRenderedChat, chatId);

    return (
        <span
            dangerouslySetInnerHTML={{ __html: formatText(textToShow) }}
            key='textIndex'
        />
    );
};

export default TextTypingEffectWithTextsFadeOut;
