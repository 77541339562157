import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, TypographyStyledGlobal, fontWeight } from '../../../theme';
import CrosstableModal from '../../Modals/DashboardModal/crosstableModal';
import { CrosstableProps, ClauseFilterLabelProps } from '../dashboardInterfaces';
import { ButtonDiv, ConversationButtonStyled } from '../styled';
import CrossingTable from '../Tables/crossingTable';
import { ReactComponent as CrosstableZoomImage } from '../../../assets/charts/crosstable-zoom.svg';

interface CrosstableIndicatorProps {
    crosstableResult: CrosstableProps
    questionLabel?: string | undefined
    selectedClauseFilterLabel?: Array<ClauseFilterLabelProps>
}

const CrosstableIndicator: FC<CrosstableIndicatorProps> = ({ crosstableResult, questionLabel, selectedClauseFilterLabel }) => {
    if (crosstableResult.base === 0) {
        return null;
    }
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation('dashboard');

    return (
        <>
            <CrossingTable
                crosstableResult={crosstableResult}
                isZoomed={false}
            />
            <ButtonDiv>
                <ConversationButtonStyled bgColor='white' bgColorHover={colors.grey1400} boxShadow={false} fontColor={colors.grey100} margin='10px 0px 0px 15px' onClick={() => setOpenModal(true)} marginLeft>
                    <CrosstableZoomImage />
                    <TypographyStyledGlobal
                        margin='0px'
                        weight={fontWeight.regular}
                    >
                        {t('addIndicatorModal.addCrosstableIndicator.displayCrosstableTitle')}
                    </TypographyStyledGlobal>
                </ConversationButtonStyled>
            </ButtonDiv>
            <CrosstableModal crosstableResult={crosstableResult} openModal={openModal} questionLabel={questionLabel} selectedClauseFilterLabel={selectedClauseFilterLabel} setOpenModal={setOpenModal} />
        </>
    );
};

export default CrosstableIndicator;
