import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Card, Divider, FormControl, MenuItem, Select, TableCell } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../theme';
import { ButtonStyled } from '../../theme/style';

export const DashboardStyled = styled.div`
   @media print {
        width: 95%;
   }
`;

export const ColumnDashboardCharts = styled.div`
   display: flex;
   flex-direction: column;
   width: 32%;
   row-gap: 24px;
   @media print {
       width: 100%;
       display: grid;
       grid-template-columns: 50% 50%;
       grid-column-gap: 1rem;
       justify-items: center;
       box-sizing: border-box;
       align-items: start;
       margin-bottom: 1rem;
       break-inside: avoid;
       break-after: avoid;
       padding: 0;
    }
    &.column-droppable {
        background: #e9ecef;
    }

    &.drag-over {
        background: #e2e8f0;
        border: 2px dashed #94a3b8;
    }
`;

export const ContainerDashboardCharts = styled.div`
   display: flex;
   gap: 19px;
   @media print {
        display: block;
        box-sizing: border-box;
        width: 100%;
    }
`;

export const IndicatorCardStyled = styled(Card)`
    && {
        background-color: ${colors.grey000};
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.04);
        border-radius: 8px;
        position: relative;
        @media print {
            box-sizing: border-box;
            margin: 0;
            width: 100%;
            box-shadow: none;
        }
    }
`;

export const IndicatorCardHeader = styled.div`
    && {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;
    }
`;

export const IndicatorCardHeaderBase = styled.div`
    && {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 18px;
    }
`;

export const KeyFigureIndicatorStyled = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
`;

export const ComputeIndicatorStyled = styled.div`
    color: ${colors.grey400};
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.sm}
`;

export const KeyFigureIndicatorFooter = styled.div`
    width: 100%;
    margin-top: 24px;
    color: ${colors.grey400};
    font-weight: ${fontWeight.regular};
    font-style: italic;
    text-align: right;
`;

export const DividerStyled = styled(Divider)`
    && {
        margin: 0;
        margin-bottom: 24px;
        color: ${colors.grey600}
    }
`;

export const ButtonCardStyled = styled(ButtonStyled)`
    && {
       padding: 0px;
       color: ${colors.grey200};
       margin: 0;
       vertical-align: baseline;
       border: none;
       font-weight: 600;
       text-decoration: underline;
       &:hover {
            background-color: transparent;
       }
       @media print {
            display: none;
        }
    }
`;

export const VerbatimContainerStyled = styled.div`
    && {
        display: flex;
        width: 100%;
        break-inside: avoid;
        break-after: avoid;
        break-before: avoid;
        margin-bottom: 15px;
    }
`;
export const AllVerbatimContainerStyled = styled.div`
    && {
        max-height: 350px;
        overflow-y: scroll;
        width: 100%;
        margin-bottom: 20px;
        &&::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &&::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: ${colors.grey600};
          }
    }
`;

export const QuoteVerbatimStyled = styled.div`
    && {
        width: 16px;
        margin-left: 16px;
        margin-right: 8px;
    }
`;

export const TextVerbatimStyled = styled.div`
    && {
        width: 85%;
        line-height: 24px;
        font-weight: 400;
        margin-left: 15px;
    }
`;

export const EmojiVerbatimStyled = styled.div`
    && {
        width: 28px;
    }
`;

export const FilterSelectStyled = styled.div`
    && {
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const StyledSelect = styled(Select)`
    && {
        border-radius: 8px;
        max-width: 250px;
        ::before {
            display: none;
        }
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    && {
        font-family: ${fontFamilyPrimary};
        ::before {
            display: none;
            }
        }
`;

export const StyledSelectFormControl = styled(FormControl)`
    && {
        min-width: 15em;
        height: 5em;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        width: 20%;
        @media print {
            display: none;
        }
    }
`;

export const SelectFiltersBar = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CardStyled = styled(Box)(() => `
    display: inline-flex;
    padding: 10px 21px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: var(--RADIUS, 5px);
    background: #FFF;
    box-shadow: 0px 10px 60px 0px rgba(33, 22, 69, 0.04);
    &:hover {
        background-color: ${colors.lighten};
        cursor: ${'pointer'};
        transition: background-color 500ms ease-in-out;
    }
`);

export interface ConversationButtonStyledProps {
    bgColor: string
    bgColorHover: string
    fontColor: string
    hasBoxShadow: boolean
    marginLeft?: boolean
}

export const ConversationButtonStyled = styled(Box)((props : ConversationButtonStyledProps) => `
    display: inline-flex;
    padding: 10px 21px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: auto;
    min-width: 120px;
    color: ${props.fontColor};
    border-radius: var(--RADIUS, 5px);
    background: ${props.bgColor};
    box-shadow: ${props.hasBoxShadow ? '0px 4px 4px 10px 0px rgba(20, 119, 130, 0.30)' : 'none'};
    &:hover {
        background-color: ${props.bgColorHover};
        cursor: ${'pointer'};
        transition: background-color 500ms ease-in-out;
    }
    float: right;
    margin-top: 10px;
    ${props.marginLeft ? 'margin-left: auto!important;' : ''}
`);

export const ButtonDivStyled = styled.div`
    display: flex;
`;

interface ChatbotContainerStyledProps {
    scroll: string
}

export const ChatbotContainerStyled = styled.div(({ scroll }: ChatbotContainerStyledProps) => `
    && {
        max-height: 350px;
        overflow-y: ${scroll};
        width: 100%;
        margin-bottom: 20px;
        &&::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &&::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: ${colors.grey600};
          }
    }
`);

export const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
`;

export const IndicatorContent = styled.div`
    transition: all 0.2s ease;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid transparent;

    &.being-dragged {
        background: rgba(255, 255, 255, 0.8);
    }
`;

export const DragAndDropIconStyled = styled(DragIndicatorIcon)`
    && {
        color: ${colors.grey500};
        padding:0;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 82px;
        z-index: 100;
        border: none;
        &:hover {
            transition: all 0.3s ease-in-out;
            color: ${colors.grey300};
            cursor: grab;
            border: none;
        }
        &:active {
            cursor: grabbing;
            border: none;
        }
        &:focus {
            outline: none;
            border:none;
        }
    }
`;

export const TableCellBodyStyled = styled(TableCell)(({ isEven, isSpecific, isTotal, cellWidth }: {isEven: boolean, isSpecific: boolean, isTotal: boolean, cellWidth: string}) => `
    && {
        font-family: ${fontFamilyPrimary};
        color: ${colors.grey200};
        font-size: ${fontSize.sm};
        background-color: ${(isSpecific && colors.specificTableBackground) || (isEven && colors.grey800) || 'white'};
        font-weight: 400;
        white-space: nowrap;
        width: ${cellWidth}px;
        border: none;
        border-bottom: 1px solid ${isSpecific ? 'white' : colors.grey600};
        padding: 8px 16px;
        ${isTotal && `border-right: 1px solid ${isSpecific ? 'white' : colors.grey600};`}
    }

`);

export const TableCellHeadStyled = styled(TableCell)(({ isTotal, cellWidth }: {isTotal: boolean, cellWidth: string}) => `
    && {
        font-family: ${fontFamilyPrimary};
        color: ${colors.grey100};
        font-size: ${fontSize.sm};
        font-weight: 600;
        width: ${cellWidth}px;
        border: none;
        padding: 8px 16px;
        ${isTotal && `border-right: 1px solid ${colors.grey600};`}
    }

`);

export const TableCellBaseStyled = styled(TableCell)(({ isTotal, cellWidth, isFirstColumn }: {isTotal: boolean, cellWidth: string, isFirstColumn: boolean}) => `
    && {
        font-family: ${fontFamilyPrimary};
        color: ${colors.grey400}!important;
        font-size: ${fontSize.s};
        font-style: italic;
        width: ${cellWidth}px;
        border: none;
        ${!isFirstColumn && `border-bottom: 1px solid ${colors.grey600};`}
        padding: 0px 8px!important;
        font-weight: 300;
        ${isTotal && `border-right: 1px solid ${colors.grey600};`}
    }

`);
