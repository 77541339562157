import { UniqueIdentifier } from '@dnd-kit/core';
import { Box, Divider } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';
import GroupModality from '../../../../../entities/modality/GroupModality';
import Modality from '../../../../../entities/modality/Modality';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../../../theme';
import useFetcher from '../../../../../utils/hooks/useFetcher';
import { DashboardParams } from '../../../../Dashboard/dashboardInterfaces';
import SortableTree from '../../../../SortableTree/SortableTree';
import { TreeItem, TreeItems } from '../../../../SortableTree/types';
import { removeItem, setProperty } from '../../../../SortableTree/utilities';
import { KPI_STATE } from '../../constants';
import { EventUpdateStatus, KpiGroupConfig, KpiModalityConfig, ModalitiesGroupProps, ModalitiesProps, RefineAnswerContentProps } from '../../dashboardModalInterface';
import ActionsButton from '../ActionsButton/actionButton';
import { ButtonRefineModalitiesStyled, RefineQuestionWrapper } from '../styled';
import AddGroupInput from './RefineIndicatorComponents/addGroupInput';
import CardSelectAnswer from './RefineIndicatorComponents/cardSelectAnswer';
import IconsLegend from './RefineIndicatorComponents/iconsLegend';
import 'react-grid-layout/css/styles.css';

// TODO: mettre dans un autre fichier
const RefineIndicatorAnswer: FC<RefineAnswerContentProps> = ({
    setCurrentContent,
    selectedQuestion,
    setCreateIndicator,
    createIndicator,
    isCrossing,
}) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();

    const [, setInputList] = useState<React.ReactNode[]>([]);
    const [modalityGroups, setModalitiesGroup] = useState<ModalitiesGroupProps[]>([]);
    const [modalities, setModalities] = useState<Modality[]>([]);
    const [newItem, setNewItem] = useState<TreeItem>();
    const [treeItems, setTreeItems] = useState<TreeItems>([]);
    const [kpiModalityConfig, setKpiModalityConfig] = useState<KpiModalityConfig[]>(isCrossing ? createIndicator?.kpiCrossingModalityConfig : createIndicator?.kpiModalityConfig);
    const [kpiGroupConfig, setKpiGroupConfig] = useState<KpiGroupConfig[]>(isCrossing ? createIndicator?.kpiCrossingGroupConfig : createIndicator?.kpiGroupConfig);
    const { data } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/questions/${selectedQuestion.id_child_question}/modalities`, useFetcher());

    useEffect(() => {
        if (data && !isCrossing && createIndicator.saveTreeItems.length === 0) {
            setModalities(data.map((modality: ModalitiesProps) => (new Modality(modality))));
        } else if (data && isCrossing && createIndicator.saveCrossingTreeItems.length === 0) {
            setModalities(data.map((modality: ModalitiesProps) => (new Modality(modality))));
        } else if (!isCrossing && (createIndicator.saveModalitiesGroup || createIndicator.saveTreeItems)) {
            setModalities(createIndicator.saveModalities.map((modality: ModalitiesProps) => (new Modality(modality))));
            setModalitiesGroup(createIndicator.saveModalitiesGroup);
            setTreeItems(createIndicator.saveTreeItems);
        } else if (isCrossing && (createIndicator.saveCrossingModalitiesGroup || createIndicator.saveCrossingTreeItems)) {
            setModalities(createIndicator.saveCrossingModalities.map((modality: ModalitiesProps) => (new Modality(modality))));
            setModalitiesGroup(createIndicator.saveCrossingModalitiesGroup);
            setTreeItems(createIndicator.saveCrossingTreeItems);
        }
    }, [data]);

    const getSortedItemsIds = () => {
        let numberOfItems = 0;
        let count = 0;
        const idsTmp = [];
        for (const item of treeItems) {
            idsTmp.push({ id: item.id, rank: count + numberOfItems });
            numberOfItems += item.children.length;
            count += 1;
        }

        return idsTmp;
    };
    const createKpiModality = () => {
        const ids = getSortedItemsIds();
        const kpiModality = [];
        const filteredModality = modalities.filter((modality) => ids.some((item) => item.id === modality.codemodalite));
        for (const modality of filteredModality) {
            const kpiModalityConfigState = createIndicator?.kpiModalityConfig.filter(({ modalityCode }) => modalityCode === modality.codemodalite)[0]?.state;
            const config = {
                label: modality.label,
                modalityCode: modality.codemodalite,
                state: kpiModalityConfigState ?? KPI_STATE.DISPLAYED,
                rank: ids.find((item) => item.id === modality.codemodalite)?.rank ?? 0,
            };
            kpiModality.push(config);
        }
        setKpiModalityConfig(kpiModality);
    };

    const createKpiGroupConfig = () => {
        const kpiGroup = [];
        const ids = getSortedItemsIds();
        for (const group of modalityGroups) {
            const item = treeItems.find((treeItem) => treeItem.id === group.idGroup);
            const config = {
                id: group.idGroup,
                groupLabel: group.label,
                rank: ids.find((itemGroup) => itemGroup.id === group.idGroup)?.rank ?? 0,
                state: group.status ?? KPI_STATE.DISPLAYED,
                modalityCodes: item?.children.map(({ id }) => id as number) ?? [],
            };
            kpiGroup.push(config);
        }
        setKpiGroupConfig(kpiGroup);
    };

    useEffect(() => {
        if ((!isCrossing && createIndicator.saveTreeItems.length === 0) || (isCrossing && createIndicator.saveCrossingTreeItems.length === 0)) {
            setTreeItems(modalities?.map((modality) => modality.toTreeItem()));
        }
    }, [modalities]);

    useEffect(() => {
        createKpiModality();
        createKpiGroupConfig();
    }, [treeItems, modalityGroups, modalities]);

    useEffect(() => {
        const ids = treeItems.map((treeItem) => treeItem.id);
        const newGroup = modalityGroups.find((modalityGroup) => !ids.includes(modalityGroup.idGroup));
        setNewItem((prevItem) => {
            if (newGroup) {
                return new GroupModality(newGroup).toTreeItem();
            }

            return prevItem;
        });
    }, [modalityGroups]);

    const handleCollapse = (id: UniqueIdentifier) => {
        setTreeItems((itemsCollapse) => setProperty(itemsCollapse, id, 'collapsed', (value) => !value));
    };

    const handleRemove = (id: UniqueIdentifier) => {
        setTreeItems((itemsRemove) => removeItem(itemsRemove, id));
    };
    const handleUpdateStatus = (id: UniqueIdentifier, event: EventUpdateStatus) => {
        setKpiModalityConfig((prevModalityConf) => {
            if (prevModalityConf) {
                const conf = {
                    ...prevModalityConf.find((modality) => modality.modalityCode === id),
                    state: event.type,
                } as KpiModalityConfig;

                const index = prevModalityConf.findIndex((modality) => modality.modalityCode === id);
                const newModalityConf = [...prevModalityConf] as KpiModalityConfig[];
                if (index !== -1) {
                    newModalityConf[index] = conf as KpiModalityConfig;

                    return newModalityConf;
                }
            }

            return prevModalityConf;
        });
    };

    const onAddBtnClick = () => {
        const uuid = uuidv4();
        setModalitiesGroup((prev) => [...prev, { idGroup: uuid, label: '', isCollapse: false, status: KPI_STATE.DISPLAYED }]);
        const addButtonGroup = (
            <div key={`group-${uuid}`}>
                <AddGroupInput
                    key={uuid}
                    handleRemove={handleRemove}
                    idGroup={uuid}
                    modalityGroups={modalityGroups}
                    onCollapse={handleCollapse}
                    setModalitiesGroup={setModalitiesGroup}
                />
            </div>
        );
        setInputList((prev) => [...prev, addButtonGroup]);
    };
    const itemBuilder = (id: UniqueIdentifier, isChildren: number = 0) => {
        const ids = modalityGroups.map((modalityGroup) => modalityGroup.idGroup);
        if (ids.includes(id as string)) {
            return (
                <AddGroupInput
                    key={id}
                    handleRemove={handleRemove}
                    idGroup={id as string}
                    modalityGroups={modalityGroups}
                    onCollapse={handleCollapse}
                    setModalitiesGroup={setModalitiesGroup}
                />
            );
        }

        const modality = modalities.find((item) => item.codemodalite === id);

        return (<CardSelectAnswer eventUpdate={handleUpdateStatus} isChildren={isChildren === 50} {...modality?.toModalitiesProps()} />);
    };

    const handleCreateIndicator = () => {
        setModalities((prevModality) => {
            const newModalities = prevModality.map((modality) => {
                const newModality = modality;
                const sameModality = kpiModalityConfig?.find((kpiModality) => kpiModality.modalityCode === newModality.codemodalite);
                if (sameModality?.state === 'EXCLUDED') {
                    newModality.excluded = true;
                }
                if (sameModality?.state === 'HIDDEN') {
                    newModality.hide = true;
                }

                return newModality;
            });

            return newModalities;
        });
        setModalitiesGroup((prevModalityGroup) => {
            const newModalities = prevModalityGroup.map((groupModality) => {
                const newGroupModality = groupModality;
                const sameModality = kpiGroupConfig?.find((kpiModality) => kpiModality.groupLabel === newGroupModality.idGroup);
                newGroupModality.status = sameModality?.state;

                return newGroupModality;
            });

            return newModalities;
        });
        if (kpiModalityConfig && kpiGroupConfig) {
            setCreateIndicator((prev) => {
                const indicatorCopy = { ...prev };
                if (isCrossing) {
                    indicatorCopy.kpiCrossingModalityConfig = kpiModalityConfig;
                    indicatorCopy.kpiCrossingGroupConfig = kpiGroupConfig;
                    indicatorCopy.saveCrossingTreeItems = treeItems;
                    indicatorCopy.saveCrossingModalities = modalities;
                    indicatorCopy.saveCrossingModalitiesGroup = modalityGroups;
                } else {
                    indicatorCopy.kpiModalityConfig = kpiModalityConfig;
                    indicatorCopy.kpiGroupConfig = kpiGroupConfig;
                    indicatorCopy.saveTreeItems = treeItems;
                    indicatorCopy.saveModalities = modalities;
                    indicatorCopy.saveModalitiesGroup = modalityGroups;
                }

                return indicatorCopy;
            });
        }
    };

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey300}
                margin='0 0 8px 0'
                size={fontSize.sm}
                weight={fontWeight.regular}
            >
                {t('dashboard:addIndicatorModal.refineIndicatorAnswer.subtitle')}1 : {selectedQuestion.label}
            </TypographyStyledGlobal>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonRefineModalitiesStyled
                    onClick={onAddBtnClick}
                    variant="text"
                >
                    {t('dashboard:addIndicatorModal.refineIndicatorAnswer.createGroup')}
                </ButtonRefineModalitiesStyled>
            </Box>
            <RefineQuestionWrapper>
                <SortableTree
                    handleCollapse={handleCollapse}
                    handleRemove={handleRemove}
                    itemBuilder={itemBuilder}
                    items={treeItems}
                    newItem={newItem}
                    setItems={setTreeItems}
                />
            </RefineQuestionWrapper>

            <Divider sx={{ marginBottom: '32px', marginTop: '32px' }} />
            <IconsLegend />
            <ActionsButton
                handleClickBack={() => setCurrentContent(isCrossing ? 'selectCrossingIndicatorQuestion' : 'selectIndicatorQuestion')}
                handleClickNext={() => {
                    handleCreateIndicator();
                    setCurrentContent(isCrossing ? 'addCrosstableIndicator' : 'selectDisplayType');
                }}
                isDisabled={false}
                labelBack={t('common:back')}
                labelNext={t('common:next')}
            />
        </>
    );
};

export default RefineIndicatorAnswer;
