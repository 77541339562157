import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontSize, fontWeight, TypographyStyledGlobal, TypographyDivStyledGlobal } from '../../../theme';
import CrossingTable from '../../Dashboard/Tables/crossingTable';
import { BigBoxModalStyled, OneLineTitleStyled } from '../styled';
import { CrosstableModalProps } from './dashboardModalInterface';
import { CloseIconModalStyled, ModalStyled, AllVerbatimCardHeader } from './styled';

const CrosstableModal: FC<CrosstableModalProps> = ({ openModal, questionLabel, setOpenModal, selectedClauseFilterLabel, crosstableResult }) => {
    const handleClose = () => {
        setOpenModal(false);
    };
    const { t } = useTranslation('dashboard');

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <BigBoxModalStyled bgcolor='white' height='auto' maxWidth='100%' width='80%'>
                <CloseIconModalStyled onClick={handleClose} />
                <AllVerbatimCardHeader>
                    <TypographyDivStyledGlobal color={colors.black000} paddingTop='10px' size={fontSize.ml} weight={fontWeight.semiBold}>
                        {questionLabel}
                        <OneLineTitleStyled>
                            <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' size={fontSize.sm} textDecoration='underline' weight={fontWeight.semiRegular}>
                                {'Filtrage : '}
                            </TypographyStyledGlobal>
                            { selectedClauseFilterLabel && selectedClauseFilterLabel.map((filter: any, index: number) => (
                                <TypographyStyledGlobal key={filter.filterLabel} color={colors.grey400} fontStyle='italic' margin='0px 0px 0px 5px' size={fontSize.sm} weight={fontWeight.semiRegular}>
                                    {index === 0 ? `${filter.filterLabel}` : ` || ${filter.filterLabel}`}{` : ${filter.modalitiesLabel}`}
                                </TypographyStyledGlobal>
                            ))}
                            { selectedClauseFilterLabel?.length === 0 && (
                                <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' margin='0px 0px 0px 5px' size={fontSize.sm} weight={fontWeight.semiRegular}>
                                    {t('filter.noFilter')}
                                </TypographyStyledGlobal>
                            )}
                        </OneLineTitleStyled>
                    </TypographyDivStyledGlobal>
                </AllVerbatimCardHeader>
                <CrossingTable crosstableResult={crosstableResult} isZoomed />
            </BigBoxModalStyled>
        </ModalStyled>
    );
};

export default CrosstableModal;
