import { FormControl, RadioGroup } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxButtonStyled } from '../../../../../../theme/style';
import { CrosstableConfigurationProps } from '../../../dashboardModalInterface';
import { FormLabelStyled, FormControlLabelStyled } from '../../styled';

interface CheckboxButtonsProps {
    configuration: CrosstableConfigurationProps;
    setConfiguration: Dispatch<SetStateAction<CrosstableConfigurationProps>>
    isNps: boolean
    hasNumericValue: boolean
}

const CheckboxButtons: FC<CheckboxButtonsProps> = ({ configuration, setConfiguration, isNps, hasNumericValue }) => {
    const { t } = useTranslation('dashboard');

    const handleSelectNPS = () => {
        setConfiguration((prev) => ({ ...prev, has_nps: !prev.has_nps }));
    };
    const handleSelectMean = () => {
        setConfiguration((prev) => ({ ...prev, has_mean: !prev.has_mean }));
    };

    return (
        <FormControl>
            <FormLabelStyled>
                {t('addIndicatorModal.commonAddIndicator.display')}
            </FormLabelStyled>
            <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}
            >
                {isNps && (
                    <FormControlLabelStyled
                        control={
                            (
                                <CheckboxButtonStyled
                                    checked={configuration.has_nps}
                                    onChange={() => handleSelectNPS()}
                                />
                            )
                        }
                        ischecked={configuration.has_nps ? 1 : 0}
                        label="NPS Net Promoter Score"
                        value="NPS"
                    />
                )}
                {hasNumericValue && (
                    <FormControlLabelStyled
                        control={
                            (
                                <CheckboxButtonStyled
                                    checked={configuration.has_mean}
                                    onChange={() => handleSelectMean()}
                                />
                            )
                        }
                        ischecked={configuration.has_mean ? 1 : 0}
                        label="Moyenne"
                        value="Average"
                    />
                )}
            </RadioGroup>
        </FormControl>
    );
};

export default CheckboxButtons;
