import { Button, Checkbox, Divider, Input, Menu, MenuItem, Radio, Select, Switch } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../index';

export const ButtonStyled = styled(Button)`
    && {
        text-transform: none;
        color: ${colors.grey300};
        border-radius: 8px;
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.m};
        font-weight: ${fontWeight.regular};
        &:hover {
            background-color: ${colors.grey700}
        }

        &:active {
            font-weight: ${fontWeight.semiBold};
            background-color: ${colors.grey000};
        }
    }
`;

export const ButtonValidated = styled(ButtonStyled)`
    && {
        padding: 12px 16px;
        background-color: ${colors.default};
        color: ${colors.grey000};
        font-weight: ${fontWeight.bold};
        box-shadow: 0px 10px 32px rgba(20, 119, 123, 0.24);
        &:hover {
            background-color: ${colors.defaultHover};
        }
        &:disabled {
            background-color: ${colors.grey600};
            color: ${colors.grey300};
        }
    }
`;

export const MenuStyled = styled(Menu)`
  && {
      .MuiMenu-list {
        padding: 0;
        box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.16);
        border-radius: 8px;
        background-color: ${colors.grey000};
        text-align: right;
    }
  }
`;

export const MenuItemsStyled = styled(MenuItem)`
    && {
        background: transparent;
        box-shadow: none;
        align-items: flex-end;
        flex-direction: column;
        padding: 16px;
        border-radius: 4px;
        color: ${colors.grey200};
        font-family: ${fontFamilyPrimary};
        &:hover {
            background-color: ${colors.grey700};
        }

        &:active{
            background-color: ${colors.grey000};
        }
        &:active *{
            font-weight: ${fontWeight.semiBold};
        }
    }
`;

export const DividerMenuStyled = styled(Divider)`
    && {
        margin: 0 8px;
        border-color: ${colors.grey700};
        &.MuiDivider-root {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;

export const InputStyled = styled(Input)`
    && {
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm};
        background-color: ${colors.grey000};
        border-radius: 8px;
        padding: 10px 16px;
        margin-top: 8px;
        color: ${colors.grey100};
        &::before {
            border: none;
        }
        &::after {
            border: none;
        }
        &:hover::after {
            border: none;
        }
        &:hover:not(.Mui-disabled):before {
            border: none;
        }
        & ::placeholder {
            color: ${colors.grey400};
            font-size: ${fontSize.sm};
            opacity: 1;
        }
    }
`;

export const RadioButtonStyled = styled(Radio)`
    && {
        color: ${colors.grey500};
        &&.Mui-checked {
            color: ${colors.alertSuccess};
    }
`;

export const CheckboxButtonStyled = styled(Checkbox)`
    && {
        color: ${colors.grey500};
        &&.Mui-checked {
            color: ${colors.alertSuccess};
    }
`;

export const SelectStyled = styled(Select)`
    && {
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.m};
        background-color: ${colors.grey000};
        border-radius: 8px;
        width: 100%;
        padding: 10px 16px;
        max-height: 50px;
        position: relative;
        & fieldset {
            border: none;
        }
        & .MuiSelect-select.MuiSelect-outlined {
            padding-left: 0;
        }
    }
`;

export const ButtonDisabledStyled = styled(ButtonStyled)`
&& {
    background-color: ${colors.grey600};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.sm};
    padding: 12px 16px;
    border-radius: 8px;
    font-style: normal;
    font-family: 'Barlow';
    &:hover {
        background-color: ${colors.grey600};
        cursor: initial;
    }
}
`;

export const ButtonWithoutBackgroundStyled = styled(ButtonStyled)`
    && {
        background-color: ${colors.grey000};
        color: ${colors.default};
        box-shadow: none;
        padding: 12px 16px;
        font-weight: ${fontWeight.semiBold};
        font-size: ${fontSize.sm};
    }
`;

export const SwitchStyled = styled(Switch)`
    && {
        & .MuiSwitch-switchBase.Mui-checked {
            color: ${colors.default};
            transform: translateX(20px) translateY(-2px);
        }
        & .MuiSwitch-switchBase {
            transform: translateY(-2px);
        }
        & .MuiSwitch-thumb {
            width: 22px;
            height: 22px;
        }
        & .Mui-disabled {
            color: ${colors.defaultDisabled}!important;
        }
        & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
            background-color:  ${colors.default};
        },
    }
`;
